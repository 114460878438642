@import 'src/design-system/styles/typography.mixins';

.root {
  width: 100%;
  margin-top: 171px;

  & > div {
    margin-top: 0;
  }
}

.skeleton {
  list-style: none;
  margin: 12px 24px;
  padding: 15px 0;

  &:last-child {
    margin-bottom: 24px;
  }
}

.title {
  @include font-style-subtitle;
  margin: 0 24px;
  color: var(--text-10);
}
